import React, {useEffect} from 'react'
import BlockContent from './block-content'
import Container from './container'
import { piioData } from '../../../PASSWORDS'

import styles from './project.module.css'

function ProjectInfo ({title, text}) {
  return (
    <div>
      <p>
        <span className={styles.projectInfoTitle}>{title} /</span>
        <span className={styles.projectInfoText}>{text}</span>
      </p>
    </div>
  )
}

function Project (props) {
  const {_rawBody, title, subtitle, client, agency, product, role, summary} = props

  useEffect(() => {
    const script = document.createElement('script');

    script.src = `//js.piio.co/${piioData.appKey}/piio.min.js`;
    script.type = "application/javascript"

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    }
  }, []);

  return (
    <article className={styles.root}>
      <Container>
        <div className={styles.grid}>
          <div className={styles.mainContent}>
            <div style={{display: "flex"}}>
              <h1 className={styles.title}>{title}</h1>
              <span className={styles.title}>-</span>
            </div>
            <h2 className={styles.subtitle}>{subtitle}</h2>
            <hr/>
            <div className={styles.head}>
              <div className={styles.projectInfo}>
                {client && <ProjectInfo title="Client" text={client}/>}
                {agency && <ProjectInfo title="Agency" text={agency}/>}
                {product && <ProjectInfo title="Product" text={product}/>}
                {role && <ProjectInfo title="Role" text={role}/>}
              </div>
              { summary && <p className={styles.summary}>{summary}</p>}
            </div>


            {_rawBody && <BlockContent blocks={_rawBody || []} />}
          </div>
        </div>
      </Container>
    </article>
  )
}

export default Project
