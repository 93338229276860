import React, {useState, useEffect} from 'react'
import {graphql} from 'gatsby'
import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
import Project from '../components/project'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import styles from './project.module.css'

export const query = graphql`
  query ($id: String!) {
    site: sanitySiteSettings(_id: {regex: "/(drafts.|)siteSettings/"}) {
      projectsPassword
    }
    sampleProject: sanitySampleProject(id: {eq: $id}) {
      id
      publishedAt
      categories {
        _id
        title
      }
      mainImage {
        crop {
          _key
          _type
          top
          bottom
          left
          right
        }
        hotspot {
          _key
          _type
          x
          y
          height
          width
        }
        asset {
          _id
        }
        alt
      }
      passwordProtected
      title
      subtitle
      client
      agency
      product
      role
      summary
      slug {
        current
      }
      _rawBody
    }
  }
`

function PasswordInput ({value, handleChange, handleSubmit, wrongPassword}) {
  return (
    <form onSubmit={handleSubmit}>
      <div style={{display: "flex", flexDirection: "column", alignItems: "center", margin: "20px", height: "160px"}}>
        <p style={{marginBottom: "10px"}}>This project is password protected.</p>
        <p style={{marginBottom: "30px"}}>Email hello@laurapedroni.com if you want to open it.</p>
        <div className={styles.passwordInputBox}>
          <input type="password" className={styles.passwordInputText} value={value} onChange={handleChange} />
          <input style={{borderRadius: "2px"}}type="submit" value="Submit" />
        </div>
        {wrongPassword && <p style={{color: "red", fontSize: "15px"}}>wrong password</p>}
      </div>
    </form>
  );
}


function PasswordPage({value, handleChange, handleSubmit, wrongPassword}) {
  return (
    <React.Fragment>
      <div style={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
        height: "100vh"
      }}>
        <PasswordInput
          value={value}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          wrongPassword={wrongPassword}
        />
      </div>
    </React.Fragment>
  )
}

function ProjectTemplate (props) {
  const {data, errors} = props
  const site = (data || {}).site
  const project = data && data.sampleProject
  const { projectsPassword } = site

  const [passwordIsKnown, setPasswordIsKnown] = useState(typeof window !== 'undefined' ? localStorage.getItem('passwordIsKnown') === "true" : false)

  const [wrongPassword, setWrongPassword] = useState(null)

  const [value, setValue] = useState('')

  useEffect(() => {
    localStorage.setItem('passwordIsKnown', passwordIsKnown);
  }, [passwordIsKnown]);

  function handleChange(event) {
    setValue(event.target.value);
    setWrongPassword(null)
  }

  function handleSubmit(event) {
    if ( value === projectsPassword) {
      setPasswordIsKnown(true)
    } else {
      setPasswordIsKnown(false)
      setWrongPassword(true)
    }
    event.preventDefault();
  }

  return (
    <Layout>
      {errors && <SEO title='GraphQL Error' />}
      {project && <SEO title={project.title || 'Untitled'} />}

      {errors && (
        <Container>
          <GraphQLErrorList errors={errors} />
        </Container>
      )}
      {project && (
        (!project.passwordProtected || passwordIsKnown)
        ? <Project {...project} />
        : <PasswordPage
            value={value}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            wrongPassword={wrongPassword}
          />
      )}
    </Layout>
  )
}

export default ProjectTemplate
