import React from 'react'
import {cn} from '../lib/helpers'
import { DynamicImage } from '../components/image'

import styles from './figure.module.css'

export default ({node}) => {
  if (!node.asset) {
    return null
  }

  let imagePlacement = styles.gridfullBodySize

  if (node.displayProperty) {
    if (node.displayProperty === "right") {
      imagePlacement = styles.gridRight
    } if (node.displayProperty === "left") {
      imagePlacement = styles.gridLeft
    } else if (node.displayProperty === "fullBodySize") {
      imagePlacement = styles.gridfullBodySize
    }
  }

  // Turns out Sanity is shit and degrades images that go through its pipeline when resizing them (when image is at its original size, no problem.)
  // const fluidProps = getFluidGatsbyImage(node.asset._ref, {maxWidth: 675, quality: 100}, clientConfig.sanity)
  // The smaller the image generated, the worse the quality degradation
  // The image with its original size isn't degraged.

  return (
    <div className={cn(styles.root, imagePlacement)}>
      <figure className={styles.figure}>
        <DynamicImage
          imageOptimizationOn={false}
          styleImg={{ width: "100%" }}
          imageContainer={node}
        />
      </figure>

    </div>
  )
}
