import React from 'react'
import { DynamicImage } from '../components/image'

const alphabet = ["a", "b", "c", "d", "e", "f", "g", "h"]

function MultipleImages({node}) {
  let renderImages = node && node.galleryImage && node.galleryImage.map(
    (image, indexImage) => {
      const imageStyle = node.imageFillsColumn
      ? {
          width: "100%",
          height: "100%",
          objectFit: "cover",
          display: "block"
        }
      : { width: "100%" }

      return (
      <figure style={{gridArea: alphabet[indexImage]}}>
        <DynamicImage
          imageOptimizationOn={false}
          styleImg={imageStyle}
          imageContainer={image}
        />
      </figure>
    )}
  )

  return(
    <div style={{
      display: "grid",
      gridTemplateAreas: node.galleryLayout,
      margin: "2rem 0"
    }}>
      {renderImages}
    </div>
  )
}

export default MultipleImages
