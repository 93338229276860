import React from 'react';
import Figure from './figure';
import MultipleImages from './multiple-images';

import {cn} from '../lib/helpers'

import styles from './serializers.module.css'


// @import '../src/styles/custom-properties.css';

// https://github.com/sanity-io/block-content-to-react
// https://www.sanity.io/docs/styling

const overline = props => (
  <span className={styles.overline}>{props.children}</span>
)

// const projectHeadingH3 = props => (
//   <span style={{ backgroundColor: "#58FFEB" }}>{props.children}</span>
// )

const BlockRenderer = props => {
  const style = props.node.style || 'normal'

  if (style === "projectHeadingH3") {
    return (
      <div className={cn(styles.contentWrapperCenter, styles.headingH3WrapperCenter)}>
        <div className={styles.contentCenter}>
          <h3 className={styles.headingH3}>{props.children}</h3>
        </div>
      </div>
    )
    } else if (style === "projectHeadingH4") {
      return (
        <div className={cn(styles.contentWrapperCenter, styles.headingH4WrapperCenter)}>
          <div className={styles.contentCenter}>
            <h4 className={styles.headingH4}>{props.children}</h4>
          </div>
        </div>
      )
    } else if (style === "projectHeadingH5") {
      return (
        <div className={cn(styles.contentWrapperCenter, styles.headingH5WrapperCenter)}>
          <div className={styles.contentCenter}>
            <h5 className={styles.headingH5}>{props.children}</h5>
          </div>
        </div>
      )
    } else if (style === "inlineHighlight") {
      return (
        <div className={cn(styles.contentWrapperCenter, styles.headingInlineHighlight)}>
          <div className={styles.contentCenter}>
            <h5 className={styles.inlineHighlight}>{props.children}</h5>
          </div>
        </div>
      )
    } else if (style === "bodyHighlight") {
    return (
      <div className={styles.contentWrapperLeft}>
        <div className={styles.contentLeft}>
          <p className={cn(styles.bodyHighlight, styles.contentLeft)}>{props.children}</p>
        </div>
      </div>
    )
  } else {
    return (
      <div className={styles.contentWrapperCenter}>
        <div className={styles.contentCenter}>
        <p>{props.children}</p>
        </div>
      </div>
    )
  }
}

{/*  */}

const serializers = {
  types: {
    multipleImages: MultipleImages, // doesn't appear in html code.
    figure: Figure,
    block: BlockRenderer
  },
  marks: {overline}
}

export default serializers
